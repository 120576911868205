import { ProductSearchProvider } from '@/modules/product/components/ProductSearch/components/ProductSearchProvider';

import { ProductSearchMenu } from '../components/ProductSearchMenu/ProductSearchMenu';

import { ProductSearchDesktopField } from './components/ProductSearchDesktopField/ProductSearchDesktopField';
import { ProductSearchDesktopOverlay } from './components/ProductSearchDesktopOverlay/ProductSearchDesktopOverlay';

type ProductSearchDesktopProps = {
  onIsOpenChange?: (isOpen?: boolean) => void;
  isOpen: boolean;
};

export const ProductSearchDesktop = ({
  onIsOpenChange,
  isOpen,
}: ProductSearchDesktopProps) => (
  <ProductSearchProvider onIsOpenChange={onIsOpenChange} isOpen={isOpen}>
    <ProductSearchDesktopOverlay>
      <ProductSearchDesktopField />
      <ProductSearchMenu />
    </ProductSearchDesktopOverlay>
  </ProductSearchProvider>
);
