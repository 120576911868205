import { FCC } from '@/types/common';

import { ProductSearchContext } from '../../../context/ProductSearchContext';
import { useProductSearch } from '../hooks/useProductSearch';

type ProductSearchProviderProps = {
  onIsOpenChange?: (isOpen?: boolean) => void;
  isOpen: boolean;
};

export const ProductSearchProvider: FCC<ProductSearchProviderProps> = ({
  children,
  onIsOpenChange,
  isOpen,
}) => {
  const productSearch = useProductSearch({ onIsOpenChange, isOpen });
  return (
    <ProductSearchContext.Provider value={productSearch}>
      {children}
    </ProductSearchContext.Provider>
  );
};
