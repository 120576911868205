import NextLink from 'next/link';
import { memo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { cn } from '@/core/ui/utils';
import { createCategoriesQueryVariables } from '@/modules/categories/factories/createCategoriesQueryVariables';
import { createCategoryHref } from '@/modules/categories/factories/createCategoryHref';
import { useLessCategoriesQuery } from '@/modules/categories/queries/LessCategories.delio.generated';
import { selectL0CategoryKey } from '@/store/slices/categoriesSlice';
import { useAppDispatch } from '@/store/storeHooks';

export const NavL0CategoriesList = memo(() => {
  const { coordinates, loading } = useUserAddressCoordinates();

  const categoriesResult = useLessCategoriesQuery({
    variables: createCategoriesQueryVariables({
      coordinates,
    }),
    skip: loading,
  });

  const categoriesData = categoriesResult.data;
  const l0Categories = categoriesData?.categories.results;
  const dispatch = useAppDispatch();

  const debouncedSelectL0Category = useDebouncedCallback(
    (value: string | null) => dispatch(selectL0CategoryKey(value)),
    DEBOUNCE_MS
  );

  return (
    // Disabled due to array of `li` should have parent element
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {l0Categories?.map((l0Category, index) => (
        <li
          key={l0Category.id}
          className={cn(
            'max-w-[85px]',
            'text-xs',
            'font-medium',
            'cursor-default',
            'h-full',
            index === 0 && 'pl-5',
            index === l0Categories.length - 1 && 'pr-5',
            'box-border',
            'whitespace-pre',
            'text-gray-800',
            'hover:text-primary-450'
          )}
        >
          <NextLink
            href={createCategoryHref({ l0CategoryKey: l0Category.key })}
            onMouseOver={() => debouncedSelectL0Category(l0Category.key)}
            onMouseLeave={() => debouncedSelectL0Category.cancel()}
            onClick={() => debouncedSelectL0Category(null)}
            className="flex h-full items-center"
            shallow
            scroll
          >
            <p>
              {
                // fix single letter, usually conjunctions, found in the last place in a verse.
                // This is inconsistent with the rules of Polish spelling.
                l0Category.name.replace(' i', '\ni')
              }
            </p>
          </NextLink>
        </li>
      ))}
    </>
  );
});
const DEBOUNCE_MS = 100;
