import dynamic from 'next/dynamic';
import { useDebouncedCallback } from 'use-debounce';

import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { cn } from '@/core/ui/utils';
import { createCategoriesQueryVariables } from '@/modules/categories/factories/createCategoriesQueryVariables';
import { useLessCategoriesQuery } from '@/modules/categories/queries/LessCategories.delio.generated';
import { selectedL0CategorySelector } from '@/store/selectors/categoriesSelectors';
import { selectL0CategoryKey } from '@/store/slices/categoriesSlice';
import { useAppDispatch, useAppSelector } from '@/store/storeHooks';

import { NavL0CategoriesList } from './NavL0CategoriesList';

const NavL1CategoriesList = dynamic(
  async () =>
    (await import('./NavL1CategoriesList/NavL1CategoriesList'))
      .NavL1CategoriesList,
  { ssr: false }
);

export const NavCategoriesList = () => {
  const dispatch = useAppDispatch();
  const debouncedSelectL0Category = useDebouncedCallback(
    (value: null) => dispatch(selectL0CategoryKey(value)),
    DEBOUNCE_MS
  );
  const { coordinates, loading: coordinatesLoading } =
    useUserAddressCoordinates();

  const categoriesResult = useLessCategoriesQuery({
    variables: createCategoriesQueryVariables({
      coordinates,
    }),
    skip: coordinatesLoading,
  });

  const loading = coordinatesLoading || categoriesResult.loading;

  const selectedL0CategoryKey = useAppSelector(selectedL0CategorySelector);

  const isDropdownOpen = !!selectedL0CategoryKey;

  return (
    <nav
      onMouseLeave={() => debouncedSelectL0Category(null)}
      onMouseMove={() => debouncedSelectL0Category.cancel()}
      className={cn(
        !loading && 'max-w-8xl',
        'm-auto',
        'w-full',
        'h-14',
        'flex',
        'items-center',
        'justify-between',
        'relative',
        'z-20'
      )}
    >
      {loading ? (
        <div
          className={cn('bg-gray-200', 'animate-pulse', 'h-full', 'w-full')}
        />
      ) : (
        <ul
          className={cn(
            'h-full',
            'box-border',
            'w-full',
            'flex',
            'items-center',
            'justify-between'
          )}
        >
          <NavL0CategoriesList />
          {isDropdownOpen && <NavL1CategoriesList />}
        </ul>
      )}
    </nav>
  );
};

const DEBOUNCE_MS = 100;
